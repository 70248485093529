import { createSlice } from "@reduxjs/toolkit";

import * as chargebeeActions from "../actions/chargebee";

const chargebeeSlice = createSlice({
  name: "chargebee",
  initialState: {
    itemList: null,
    isLoading: true,
    hasError: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(chargebeeActions.retrieveItemList.pending, (state, action) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(chargebeeActions.retrieveItemList.fulfilled, (state, action) => {
        state.itemList = action.payload;
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(chargebeeActions.retrieveItemList.rejected, (state, action) => {
        state.hasError = true;
        state.isLoading = false;
      });
  },
});

export default chargebeeSlice;
