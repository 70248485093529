import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { GENERATE_NEW_URL, RETRIEVE_ITEM } from "../types/chargebee";
import { showErrorMessage } from "../../helper/message";

export const retrieveItemList = createAsyncThunk(
  RETRIEVE_ITEM,
  async (payload, { dispatch }) => {
    try {
      const response = await axios.post(
        `/chargebee/retrieve-item-list`,
        payload
      );
      return response.data;
    } catch (error) {
      showErrorMessage(error);
    }
  }
);

export const generateCheckoutNewURL = createAsyncThunk(
  GENERATE_NEW_URL,
  async (payload, { dispatch }) => {
    console.log("payload", payload);
    try {
      const response = await axios.post(
        `/chargebee/generate-checkout-new-url`,
        payload
      );
      console.log("response.data", response.data);
    } catch (error) {
      showErrorMessage(error);
    }
  }
);
