import { combineReducers } from "redux";
import { thunk } from "redux-thunk";

import { configureStore } from "@reduxjs/toolkit";

import authSlice from "./reducers/auth";
import builderSlice from "./reducers/builder";
import memberSlice from "./reducers/members";
import roleSlice from "./reducers/role";
import chargebeeSlice from "./reducers/chargebee";
import { LOGOUT_USER } from "./types/auth";

const combinedReducer = combineReducers({
  auth: authSlice.reducer,
  builder: builderSlice.reducer,
  member: memberSlice.reducer,
  role: roleSlice.reducer,
  chargebee: chargebeeSlice.reducer,
});

const rootReducer = (state, action) => {
  if (action.type.includes(LOGOUT_USER)) {
    state = undefined;
  }
  return combinedReducer(state, action);
};

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }).concat(thunk),
});

export default store;
