import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  ADD_COLLECTIONS,
  ADD_COLLECTIONS_FIELD,
  ADD_ENTRY,
  DELETE_COLLECTION,
  DELETE_COLLECTIONS_FIELD,
  DELETE_ENTRY,
  FETCH_COLLECTIONS,
  FETCH_COLLECTION_BY_ID,
  FETCH_CONTENT_BY_ID,
  FETCH_VALIDATION,
  UPDATE_COLLECTIONS_FIELD,
  UPDATE_ENTRY,
  UPDATE_VALIDATION,
} from "../types/builder";
import { message } from "antd";
import { showErrorMessage } from "../../helper/message";

/* collection */

export const fetchCollections = createAsyncThunk(
  FETCH_COLLECTIONS,
  async () => {
    const response = await axios.get("/builder/collections");
    return response.data;
  }
);

export const fetchCollectionByID = createAsyncThunk(
  FETCH_COLLECTION_BY_ID,
  async ({ collectionId }) => {
    const response = await axios.get(`/builder/collection/${collectionId}`);
    return response.data;
  }
);

export const addNewCollection = createAsyncThunk(
  ADD_COLLECTIONS,
  async ({ collectionName, navigate }, { dispatch, getState }) => {
    try {
      await axios.post("/builder/collection-create", { collectionName });
      dispatch(fetchCollections())
        .unwrap()
        .then(() => {
          const { builder } = getState();
          const collection = builder.collections.find(
            (c) => c.name === collectionName
          );
          localStorage.setItem("key", collection._id);
          navigate(`/collection-builder/${collection._id}`);
        });
    } catch (error) {
      showErrorMessage(error);
    }
  }
);

export const deleteCollection = createAsyncThunk(
  DELETE_COLLECTION,
  async ({ collectionId }) => {
    try {
      const response = await axios.post(
        `/builder/collection-delete?id=${collectionId}`
      );
      return response.data;
    } catch (error) {
      showErrorMessage(error);
    }
  }
);

/* field */

export const addCollectionField = createAsyncThunk(
  ADD_COLLECTIONS_FIELD,
  async (data, { dispatch }) => {
    try {
      const { collectionId } = data;
      if (!collectionId) {
        message.error("collectionId required!");
        return false;
      }
      await axios.post("/collection/field-create", data);
      dispatch(fetchCollectionByID({ collectionId }));
    } catch (error) {
      showErrorMessage(error);
    }
  }
);

export const updateCollectionField = createAsyncThunk(
  UPDATE_COLLECTIONS_FIELD,
  async (data, { dispatch }) => {
    try {
      const { collectionId } = data;
      if (!collectionId) {
        message.error("collectionId required!");
        return false;
      }
      await axios.put("/collection/field-update", data);
      dispatch(fetchCollectionByID({ collectionId }));
    } catch (error) {
      showErrorMessage(error);
    }
  }
);

export const deleteCollectionField = createAsyncThunk(
  DELETE_COLLECTIONS_FIELD,
  async ({ collectionId, fieldID }, { dispatch }) => {
    try {
      await axios.post(
        `/collection/field-delete?collectionId=${collectionId}&fieldID=${fieldID}`
      );
      dispatch(fetchCollectionByID({ collectionId }));
    } catch (error) {
      showErrorMessage(error);
    }
  }
);

/* content */

export const fetchContentByID = createAsyncThunk(
  FETCH_CONTENT_BY_ID,
  async ({ collectionId }) => {
    try {
      const response = await axios.get(`/content/${collectionId}`);
      return response.data;
    } catch (error) {
      showErrorMessage(error);
    }
  }
);

export const addNewEntry = createAsyncThunk(
  ADD_ENTRY,
  async (data, { dispatch }) => {
    try {
      const { collectionId } = data;
      await axios.post("/content/create", data);
      dispatch(fetchContentByID({ collectionId }));
    } catch (error) {
      showErrorMessage(error);
    }
  }
);

export const editEntry = createAsyncThunk(
  UPDATE_ENTRY,
  async (data, { dispatch }) => {
    try {
      const { collectionId } = data;
      await axios.put("/content/update", data);
      dispatch(fetchContentByID({ collectionId }));
    } catch (error) {
      showErrorMessage(error);
    }
  }
);

export const deleteEntry = createAsyncThunk(
  DELETE_ENTRY,
  async (data, { dispatch }) => {
    try {
      const { collectionId } = data;
      await axios.delete(
        `/content/delete/${data.docID}?collectionName=${data.collectionName}`
      );
      dispatch(fetchContentByID({ collectionId }));
    } catch (error) {
      showErrorMessage(error);
    }
  }
);

/* Validations API */

export const fetchValidationByID = createAsyncThunk(
  FETCH_VALIDATION,
  async (collectionId) => {
    try {
      const response = await axios.get(
        `/builder/validations-code/${collectionId}`
      );
      return response.data;
    } catch (error) {
      showErrorMessage(error);
    }
  }
);

export const updateValidationsCode = createAsyncThunk(
  UPDATE_VALIDATION,
  async (data, { dispatch }) => {
    try {
      await axios.put(`/builder/validation-update/${data.collectionId}`, data);
      dispatch(fetchValidationByID(data.collectionId));
    } catch (error) {
      showErrorMessage(error);
    }
  }
);
