import { useEffect, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Button, Flex, Modal, Space, Table } from "antd";

import {
  DeleteOutlined,
  ExclamationCircleOutlined,
  LockOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import Editor from "@monaco-editor/react";

import { useCollectionStatus } from "../../hooks";
import AddNewFiels from "./components/AddNewField";
import ModuleLayout from "./components/ModuleLayout";
import PageTitle from "../../components/Page/PageTitle";
import { CollectionFieldTypes } from "../../helper/constants";
import {
  deleteCollection,
  deleteCollectionField,
  fetchCollectionByID,
  fetchValidationByID,
  updateValidationsCode,
} from "../../redux/actions/builder";

const Content = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { collectionId } = useParams();
  const permissionCheck = useCollectionStatus();

  const collection = useSelector((state) => state.builder.collection);
  const validation = useSelector((state) => state.builder.validation);
  const check = permissionCheck(collectionId);

  const [code, setCode] = useState("");

  useEffect(() => {
    dispatch(fetchCollectionByID({ collectionId }));
    dispatch(fetchValidationByID(collectionId));
  }, [collectionId, dispatch]);

  useEffect(() => {
    if (validation) {
      setCode(validation);
    } else {
      setCode("");
    }
  }, [validation]);

  const handleUpdateCode = () => {
    dispatch(updateValidationsCode({ code, collectionId: collection?._id }));
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => {
        const type = CollectionFieldTypes.find((c) => c.value === record.type);
        return (
          <Space>
            <Avatar size="small" shape="square" icon={type?.icon} />
            {text}
          </Space>
        );
      },
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (text, record) => {
        const type = CollectionFieldTypes.find((c) => c.value === record.type);
        return type?.label;
      },
    },
    {
      title: "Required",
      dataIndex: "required",
      key: "required",
      width: 100,
      render: (text) =>
        text && (
          <div style={{ fontSize: 24, lineHeight: "24px", color: "red" }}>
            *
          </div>
        ),
    },
    {
      title: "Unique",
      dataIndex: "unique",
      key: "unique",
      width: 100,
      render: (text) => text && <LockOutlined />,
    },
    {
      title: "Action",
      dataIndex: "",
      key: "action",
      width: 120,
      render: (text, record) => (
        <Space>
          <AddNewFiels edit={record} disableEdit={!check.update} />
          <Button
            danger
            size="small"
            shape="circle"
            icon={<DeleteOutlined />}
            disabled={collection?.name === "User" || !check.delete}
            onClick={() =>
              Modal.confirm({
                title: "Are you sure you want to delete this field?",
                icon: (
                  <ExclamationCircleOutlined
                    style={{ color: "red", paddingTop: 1 }}
                  />
                ),
                cancelText: "No",
                okText: "Yes",
                onOk() {
                  dispatch(
                    deleteCollectionField({
                      collectionId: collectionId,
                      fieldID: record._id,
                    })
                  );
                },
              })
            }
          />
        </Space>
      ),
    },
  ];

  return (
    <ModuleLayout>
      <PageTitle
        title={collection?.name}
        subtitle={"Build the data architecture of your content"}
        action={
          <Flex gap={8}>
            <AddNewFiels disableAdd={!check.create} />
            <Button
              danger
              type="primary"
              disabled={collection?.name === "User"}
              onClick={() => {
                Modal.confirm({
                  title: "Are you sure you want to delete this collection?",
                  icon: (
                    <ExclamationCircleOutlined
                      style={{ color: "red", paddingTop: 1 }}
                    />
                  ),
                  cancelText: "No",
                  okText: "Yes",
                  onOk() {
                    dispatch(deleteCollection({ collectionId }));
                    navigate("/collection-builder");
                  },
                });
              }}
            >
              Delete Collection
            </Button>
          </Flex>
        }
      />
      <Space direction="vertical">
        <Table
          columns={columns}
          dataSource={collection?.fields}
          rowKey={(r) => r._id}
          pagination={false}
          size="small"
        />
        <PageTitle
          title="Custom Validation"
          subtitle="your function must return true or false"
        />
        <div
          style={{
            border: "1px solid black",
            marginTop: -20,
            borderRadius: 5,
            padding: 5,
          }}
        >
          <Editor
            height="50vh"
            defaultLanguage="javascript"
            defaultValue="// write code ..."
            onChange={(value, event) => setCode(value)}
            value={code}
          />
        </div>
        <Space direction>
          <Button
            disabled={!code || validation === code}
            type="primary"
            onClick={() => {
              setCode(validation);
            }}
            icon={<SaveOutlined />}
          >
            cancel
          </Button>
          <Button
            disabled={!code || validation === code}
            type="primary"
            onClick={handleUpdateCode}
            icon={<SaveOutlined />}
          >
            Save
          </Button>
        </Space>
      </Space>
    </ModuleLayout>
  );
};

export default Content;
