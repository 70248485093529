import { useEffect } from "react";

import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Button, Modal, Space, Table } from "antd";

import { DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons";

import AddNew from "./components/AddNewContent";
import ModuleLayout from "./components/ModuleLayout";
import PageTitle from "../../components/Page/PageTitle";
import {
  deleteEntry,
  fetchCollectionByID,
  fetchContentByID,
} from "../../redux/actions/builder";
import AddNewContent from "./components/AddNewContent";
import { useContentStatus } from "../../hooks";

const Content = () => {
  const dispatch = useDispatch();
  const { collectionId } = useParams();
  const permissionCheck = useContentStatus();

  const content = useSelector((state) => state.builder.content);
  const collection = useSelector((state) => state.builder.collection);
  const check = permissionCheck(collectionId);

  useEffect(() => {
    dispatch(fetchContentByID({ collectionId }));
    dispatch(fetchCollectionByID({ collectionId }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collectionId]);

  const columns = collection?.fields.map((d) => {
    if (d.type === "image") {
      return {
        title: d.name,
        dataIndex: d.name,
        key: d._id,
        render: (text) =>
          text ? (
            <Avatar shape="square" src={text} style={{ margin: "-24px 0" }} />
          ) : null,
      };
    } else if (d.type === "rich-text") {
      return {
        title: d.name,
        dataIndex: d.name,
        key: d._id,
        render: (text) => text?.replace(/<[^>]+>/g, ""),
      };
    } else if (d.type === "date-time") {
      return {
        title: d.name,
        dataIndex: d.name,
        key: d._id,
        render: (text) => dayjs(text).format("DD/MM/YYYY - h:mm A"),
      };
    } else if (d.type === "switch") {
      return {
        title: d.name,
        dataIndex: d.name,
        key: d._id,
        render: (text) => (text ? "True" : "False"),
      };
    } else if (d.type === "color") {
      return {
        title: d.name,
        dataIndex: d.name,
        key: d._id,
        render: (text) => text,
      };
    } else {
      return {
        title: d.name,
        dataIndex: d.name,
        key: d._id,
      };
    }
  });

  if (columns) {
    columns.push({
      title: "Action",
      dataIndex: "",
      width: 120,
      fixed: "right",
      render: (record) => (
        <Space>
          <AddNewContent edit={record} disableEdit={!check.update} />
          <Button
            danger
            shape="circle"
            size="small"
            icon={<DeleteOutlined />}
            disabled={collection?.name === "User" || !check.delete}
            onClick={() =>
              Modal.confirm({
                title: "Are you sure you want to delete it? ",
                icon: <ExclamationCircleOutlined />,
                cancelText: "No",
                okText: "Yes",
                onOk() {
                  dispatch(
                    deleteEntry({
                      docID: record._id,
                      collectionId: collection._id,
                      collectionName: collection.name,
                    })
                  );
                },
              })
            }
          />
        </Space>
      ),
    });
  }

  return (
    <ModuleLayout>
      <PageTitle
        title={collection?.name}
        subtitle={`${content?.length} entry found`}
        action={<AddNew />}
      />
      <Table
        size="small"
        columns={columns}
        dataSource={content}
        rowKey={(r) => r._id}
      />
    </ModuleLayout>
  );
};

export default Content;
