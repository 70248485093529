import React from "react";
import ModuleLayout from "./components/ModuleLayout";
import { Typography } from "antd";

const CollectionBuilder = () => {
  return (
    <ModuleLayout>
      <Typography>Select the collection</Typography>
    </ModuleLayout>
  );
};

export default CollectionBuilder;
