import { Flex, Typography } from "antd";

const PageTitle = ({ title, subtitle, action }) => {
  return (
    <div style={{ marginBottom: 24 }}>
      <Flex align="end" justify="space-between">
        <Flex vertical>
          <Typography.Title level={4} style={{ marginTop: 0 }}>
            {title}
          </Typography.Title>
          {subtitle && <Typography>{subtitle}</Typography>}
        </Flex>
        {action}
      </Flex>
    </div>
  );
};

export default PageTitle;
