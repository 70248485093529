import { useState } from "react";
import { Table, Button, Form, Modal, Space } from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";

import { deleteMember } from "../../../redux/actions/members";
import PageTitle from "../../../components/Page/PageTitle";
import InviteUser from "./InviteUser";

const Members = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const [newMemberModel, setNewMemberModel] = useState(false);
  const [memberEdit, setMemberEdit] = useState(undefined);

  const members = useSelector((state) => state.member.members);
  const rolesData = useSelector((state) => state.role.roles);

  const handleModelCancel = () => {
    setNewMemberModel(false);
    setMemberEdit(undefined);
    form.resetFields();
  };

  const handleDelete = (id) => {
    dispatch(deleteMember(id));
  };

  const columns = [
    {
      title: "First Name",
      dataIndex: "firstName",
      key: "firstName",
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      key: "lastName",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    // {
    //   title: "Password",
    //   dataIndex: "password",
    //   key: "password",
    // },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
    },
    {
      title: "Action",
      dataIndex: "",
      key: "",
      width: 120,
      render: (text) => (
        <Space>
          <Button
            size="small"
            shape="circle"
            icon={<EditOutlined />}
            onClick={() => setMemberEdit(text)}
          />
          <Button
            danger
            size="small"
            shape="circle"
            icon={<DeleteOutlined />}
            onClick={() =>
              Modal.confirm({
                title: "Are you sure you want to delete it? ",
                icon: (
                  <ExclamationCircleOutlined
                    style={{ color: "red", paddingTop: 1 }}
                  />
                ),
                cancelText: "No",
                okText: "Yes",
                onOk() {
                  handleDelete(text?._id);
                },
              })
            }
          />
        </Space>
      ),
    },
  ];

  return (
    <>
      <PageTitle
        title="Members"
        subtitle=""
        action={
          <Button type="primary" onClick={() => setNewMemberModel(true)}>
            <PlusOutlined />
            Add New Member
          </Button>
        }
      />
      <Table
        size="small"
        columns={columns}
        dataSource={members}
        rowKey={(r) => r._id}
        pagination={false}
      />
      <Modal
        forceRender
        width={550}
        style={{ top: 24 }}
        title={`Add new user`}
        open={newMemberModel || memberEdit}
        onCancel={handleModelCancel}
        footer={[
          <Button onClick={handleModelCancel} key="cancel">
            Cancel
          </Button>,
          <Button
            key="ok"
            type="primary"
            htmlType="submit"
            onClick={() => form.submit()}
          >
            {memberEdit ? "Update" : "Add"}
          </Button>,
        ]}
      >
        <InviteUser
          form={form}
          memberEdit={memberEdit}
          handleModelCancel={handleModelCancel}
          rolesData={rolesData}
        />
      </Modal>
    </>
  );
};

export default Members;
