import { useCallback, useState } from "react";

import { Handle, Position } from "reactflow";
import { Card, Flex, Input, Modal, Typography } from "antd";

function TextUpdaterNode(props) {
  const { id, isConnectable } = props;

  const [isModalOpen, setIsModalOpen] = useState(false);

  const onChange = useCallback((evt) => {
    console.log(evt.target.value);
  }, []);

  return (
    <>
      <Card size="small" onClick={() => setIsModalOpen(true)}>
        <Card.Meta
          title={`${id}. Action`}
          description={"An event performs after it starts"}
        />
        <Handle
          id={id}
          type="target"
          position={Position.Top}
          isConnectable={isConnectable}
        />
        <Handle
          id={id}
          type="source"
          position={Position.Bottom}
          isConnectable={isConnectable}
        />
      </Card>
      <Modal
        title="Basic Modal"
        open={isModalOpen}
        onOk={() => setIsModalOpen(false)}
        onCancel={() => setIsModalOpen(false)}
      >
        <Flex vertical>
          <Typography.Text htmlFor="text">Text</Typography.Text>
          <Input id="text" name="text" onChange={onChange} className="nodrag" />
        </Flex>
      </Modal>
    </>
  );
}

export default TextUpdaterNode;
