import { useEffect, useState } from "react";
import { Modal, Radio, Flex, Spin } from "antd";
import Axios from "axios";
import { useDispatch, useSelector } from "react-redux";

import {
  // generateCheckoutNewURL,
  retrieveItemList,
} from "../../redux/actions/chargebee";

const Checkout = () => {
  const [paymentURL, setPaymentURL] = useState("");
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const chargebee = useSelector((state) => state.chargebee.itemList);

  useEffect(() => {
    if (chargebee?.listItems.length > 0) {
      setLoading(false);
    }
  }, [chargebee]);

  const onChange = async (e) => {
    setLoading(true);
    dispatch(retrieveItemList({ periodUnit: e.target.value }));
  };

  const handleCustomerSubscription = async (data) => {
    setLoading(true);
    try {
      const customerSubscription = {
        customerId: "AzqEwpU0DA2lTtcm",
        planPriceId: data.id,
        redirectionUrl: "/",
        frequency: data.period_unit,
      };
      // dispatch(generateCheckoutNewURL({ customerSubscription }));
      await Axios.post("/chargebee/generate-checkout-new-url", {
        customerSubscription,
      }).then((response) => {
        console.log("response.data", response.data.url);
        setPaymentURL(response.data.url);
      });
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading && (
        <Flex
          align="center"
          justify="center"
          style={{
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: "1000",
            backgroundColor: "rgba(255, 255, 255, 1)",
          }}
        >
          <Spin size="large" />
        </Flex>
      )}
      {chargebee?.listItems?.map((item) => {
        return (
          <ul>
            <li>{JSON.stringify(item)}</li>
            <button onClick={() => handleCustomerSubscription(item)}>
              {item?.id}
            </button>
          </ul>
        );
      })}

      <div>
        <Radio.Group onChange={onChange}>
          <Radio value="month">Monthly</Radio>
          <Radio value="year">Yearly</Radio>
        </Radio.Group>
      </div>
      <Modal
        title={false}
        open={paymentURL}
        closable={false}
        footer={false}
        width={427}
        style={{
          top: 15,
        }}
      >
        <div
          style={{
            textAlign: "center",
            margin: -8,
          }}
        >
          <iframe
            src={paymentURL}
            title="description"
            height="450px"
            width="400px"
            style={{ border: "none" }}
          ></iframe>
        </div>
      </Modal>
    </>
  );
};
export default Checkout;
