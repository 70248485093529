import axios from "axios";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Form, Input, Row, message } from "antd";

import { updateUser } from "../../redux/actions/auth";

import PageTitle from "../../components/Page/PageTitle";

const Account = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const user = useSelector((state) => state.auth.user);

  const [checkPassword, setCheckPassword] = useState(true);
  const [disablePassword, setDisablePassword] = useState(true);
  const [correctPassword, setCorrectPassword] = useState(false);
  const [changePassword, setChangePassword] = useState(true);

  const handleCheckPassword = () => {
    axios
      .post("/account/check-password", {
        password: form.getFieldValue("password"),
      })
      .then((response) => {
        setCheckPassword(response.data);
        setCorrectPassword(response.data);
      });
  };

  const handleChangePassword = () => {
    axios
      .post("/account/change-password", {
        password: form.getFieldValue("new-password"),
      })
      .then((response) => {
        message.success("Password changed successfully!");
        form.setFieldValue("password", "");
        form.setFieldValue("new-password", "");
        form.setFieldValue("confirm", "");
        setCheckPassword(true);
        setDisablePassword(true);
        setCorrectPassword(false);
        setChangePassword(true);
      });
  };

  const onSubmit = (values) => {
    dispatch(updateUser({ data: values }));
  };

  const onSubmitFailed = (errorInfo) => {
    console.error("Failed:", errorInfo);
  };

  return (
    user && (
      <>
        <PageTitle title="Account" subtitle="Manage your account settings" />
        <Form
          form={form}
          layout="vertical"
          style={{ flex: 2 }}
          name="signup"
          initialValues={user}
          onFinish={onSubmit}
          onFinishFailed={onSubmitFailed}
        >
          <Row gutter={16}>
            <Col md={7} span={24}>
              <Form.Item
                label="First Name"
                name="firstName"
                rules={[
                  {
                    required: true,
                    message: "Please input your first name!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col md={7} span={24}>
              <Form.Item
                label="Last Name"
                name="lastName"
                rules={[
                  {
                    required: true,
                    message: "Please input your last name!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col md={7} span={24}>
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    type: "email",
                    message: "The input is not valid E-mail!",
                  },
                  {
                    required: true,
                    message: "Please input your email!",
                  },
                ]}
              >
                <Input autoComplete="email" readOnly disabled />
              </Form.Item>
            </Col>
            <Col md={3} span={24}>
              <Form.Item label="&nbsp;">
                <Button type="primary" htmlType="submit" block>
                  Save Changes
                </Button>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col md={7} span={24}>
              <Form.Item
                label="Password"
                name="password"
                help={
                  !checkPassword
                    ? "wrong password"
                    : "write your current password to change it"
                }
                validateStatus={!checkPassword ? "error" : "default"}
              >
                <Input.Password
                  onChange={(text) => {
                    if (text) setDisablePassword(false);
                    else setDisablePassword(true);
                  }}
                />
              </Form.Item>
            </Col>
            {correctPassword ? (
              <>
                <Col md={7} span={24}>
                  <Form.Item
                    label="New Password"
                    name="new-password"
                    rules={[
                      {
                        required: true,
                        message: "This field is required!",
                      },
                    ]}
                  >
                    <Input.Password />
                  </Form.Item>
                </Col>
                <Col md={7} span={24}>
                  <Form.Item
                    name="confirm"
                    label="Confirm Password"
                    dependencies={["new-password"]}
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: "Please confirm your password!",
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (
                            !value ||
                            getFieldValue("new-password") === value
                          ) {
                            setChangePassword(false);
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error(
                              "The new password that you entered do not match!"
                            )
                          );
                        },
                      }),
                    ]}
                  >
                    <Input.Password autoComplete="new-password" />
                  </Form.Item>
                </Col>
                <Col md={3} span={24}>
                  <Form.Item label="&nbsp;">
                    <Button
                      type="primary"
                      block
                      onClick={handleChangePassword}
                      disabled={changePassword}
                    >
                      Change
                    </Button>
                  </Form.Item>
                </Col>
              </>
            ) : (
              <Col md={7} span={24}>
                <Form.Item label="&nbsp;">
                  <Button
                    onClick={handleCheckPassword}
                    disabled={disablePassword}
                  >
                    Check Password
                  </Button>
                </Form.Item>
              </Col>
            )}
          </Row>
        </Form>
      </>
    )
  );
};

export default Account;
