import { Card, Flex, Modal, Select, Typography } from "antd";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Handle, Position } from "reactflow";

const ActionNode = (props) => {
  const { id, isConnectable } = props;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [collection, setCollection] = useState();
  const [field, setField] = useState();
  const collections = useSelector((state) => state.builder.collections);
  console.log("field", field);
  return (
    <>
      <Card size="small" onClick={() => setIsModalOpen(true)}>
        <Card.Meta
          title={`${id}. Trigger`}
          description={"An event that starts your action"}
        />
        <Handle
          id={id}
          type="source"
          position={Position.Bottom}
          isConnectable={isConnectable}
        />
      </Card>
      <Modal
        open={isModalOpen}
        title="Select a trigger"
        onOk={() => setIsModalOpen(false)}
        onCancel={() => setIsModalOpen(false)}
        style={{ top: 20 }}
      >
        <Flex vertical gap={16}>
          <Flex vertical>
            <Typography.Text>Collection</Typography.Text>
            <Select
              style={{ width: "100%" }}
              placeholder="Select Collection"
              onChange={(value) => setCollection(value)}
            >
              {collections.map((collection) => (
                <Select.Option key={collection._id} value={collection._id}>
                  {collection.name}
                </Select.Option>
              ))}
            </Select>
          </Flex>
          {collection && (
            <Flex vertical>
              <Typography.Text>Field</Typography.Text>
              <Select
                style={{ width: "100%" }}
                placeholder="Select Field"
                onChange={(value) => setField(value)}
              >
                {collections
                  .find((c) => String(c._id) === String(collection))
                  .fields.map((field) => (
                    <Select.Option key={field._id} value={field._id}>
                      {field.name}
                    </Select.Option>
                  ))}
              </Select>
            </Flex>
          )}
        </Flex>
      </Modal>
    </>
  );
};

export default ActionNode;
