import { useSelector } from "react-redux";
import { Button, Form, Input, Select } from "antd";
import PageTitle from "../../components/Page/PageTitle";
import { useState } from "react";
import axios from "axios";
import { showErrorMessage } from "../../helper/message";
import { useNavigate } from "react-router-dom";

const Database = () => {
  const navigate = useNavigate();

  const user = useSelector((state) => state.auth.user);

  const dbUrl = user.database ? user.database.url : null;
  const dbName = user.database ? user.database.name : null;
  const dbType = user.database ? user.database.type : null;

  const [loading, setLoading] = useState(false);

  const onFinish = (values) => {
    setLoading(true);
    axios
      .post("/builder/db-create", values)
      .catch((error) => {
        showErrorMessage(error);
      })
      .finally(() => {
        setLoading(false);
        navigate("/collection-builder");
      });
  };

  return (
    <>
      <PageTitle title="Database" subtitle="create your local database" />
      <Form
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 600 }}
        initialValues={
          dbUrl
            ? {
                dbName,
                dbType,
              }
            : {
                dbType: "mongodb",
              }
        }
        autoComplete="off"
        onFinish={onFinish}
        disabled={loading || dbUrl}
      >
        <Form.Item
          name="dbType"
          label="Database Type"
          rules={[{ required: true, message: "This field is required!" }]}
        >
          <Select>
            <Select.Option value="mongodb">MongoDB</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="dbName"
          label="Database name"
          rules={[{ required: true, message: "This field is required!" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          wrapperCol={{
            offset: 8,
            span: 16,
          }}
        >
          <Button type="primary" htmlType="submit">
            Create Database
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default Database;
