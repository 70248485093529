import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";

class MyUploadAdapter {
  loader;

  constructor(loader) {
    this.loader = loader;
  }

  upload() {
    const reader = new FileReader();

    return new Promise((resolve, reject) => {
      reader.addEventListener("load", () => {
        resolve({ default: reader.result });
      });

      reader.addEventListener("error", (err) => {
        reject(err);
      });

      reader.addEventListener("abort", () => {
        reject();
      });

      this.loader.file.then((file) => {
        reader.readAsDataURL(file);
      });
    });
  }
}

const Editor = ({ data = "", handleChange }) => {
  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return new MyUploadAdapter(loader);
    };
  }

  return (
    <CKEditor
      config={{ extraPlugins: [uploadPlugin] }}
      data={data}
      editor={ClassicEditor}
      onChange={(event, editor) => {
        const data = editor.getData();
        handleChange(data);
      }}
    />
  );
};

export default Editor;
