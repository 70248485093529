import axios from "axios";
import {
  FETCH_USER,
  LOGOUT_USER,
  LOGIN_USER,
  REGISTER_USER,
  UPDATE_USER,
} from "../types/auth";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { showErrorMessage } from "../../helper/message";

export const fetchUser = createAsyncThunk(FETCH_USER, async () => {
  const response = await axios.get("/account/user");
  return response.data;
});

export const loginUser = createAsyncThunk(LOGIN_USER, async ({ data }) => {
  try {
    const response = await axios.post("/auth/login", data);
    return response.data;
  } catch (error) {
    showErrorMessage(error);
  }
});

export const logout = createAsyncThunk(LOGOUT_USER, async () => {
  await axios.get("/auth/logout");
});

export const signup = createAsyncThunk(REGISTER_USER, async ({ data }) => {
  try {
    const response = await axios.post("/auth/signup", data);
    return response.data;
  } catch (error) {
    showErrorMessage(error);
  }
});

export const updateUser = createAsyncThunk(UPDATE_USER, async ({ data }) => {
  try {
    await axios.put("/account/user", data);
  } catch (error) {
    showErrorMessage(error);
  }
});
