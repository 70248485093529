import { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Empty,
  Form,
  Input,
  Modal,
  Row,
  Space,
  Typography,
} from "antd";
import { addNewRole, updateRole } from "../../../redux/actions/role";
import { useDispatch, useSelector } from "react-redux";
import { PlusOutlined } from "@ant-design/icons";

const AddNewRole = ({ editData, setEditData }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const [openModal, setOpenModal] = useState(false);
  const [refreshKey, setRefreshKey] = useState();
  const [selectedArray, setSelectedArray] = useState([]);
  const [contentManagerArray, setContentManagerArray] = useState([]);

  const collections = useSelector((state) => state.builder.collections);

  useEffect(() => {
    if (editData) {
      form.setFieldsValue({
        name: editData?.name,
        description: editData?.description,
        newCollection: editData?.collectionBuilder?.newCollection,
      });
    }
  }, [editData, form]);

  const handleModelCancel = () => {
    setRefreshKey(new Date());
    setEditData(undefined);
    setSelectedArray([]);
    setContentManagerArray([]);
    setOpenModal(false);
    form.resetFields();
  };
  const handleChange = (checkedValues, collection) => {
    const collectionsArray = {
      collectionId: collection._id,
      collectionName: collection.name,
      read: checkedValues.includes("READ"),
      create: checkedValues.includes("CREATE"),
      update: checkedValues.includes("UPDATE"),
      delete: checkedValues.includes("DELETE"),
    };
    const index = selectedArray.findIndex(
      (data) => data.collectionName === collection.name
    );
    if (index === -1) {
      setSelectedArray([...selectedArray, collectionsArray]);
    } else {
      let new_array = selectedArray;
      new_array[index] = collectionsArray;
      setSelectedArray(new_array);
    }
  };

  const handleContentManager = (checkedValues, collection) => {
    const collectionsArray = {
      collectionId: collection._id,
      collectionName: collection.name,
      read: checkedValues.includes("READ"),
      create: checkedValues.includes("CREATE"),
      update: checkedValues.includes("UPDATE"),
      delete: checkedValues.includes("DELETE"),
    };
    const index = contentManagerArray.findIndex(
      (data) => data.collectionName === collection.name
    );

    if (index === -1) {
      setContentManagerArray([...contentManagerArray, collectionsArray]);
    } else {
      let new_array = contentManagerArray;
      new_array[index] = collectionsArray;
      setContentManagerArray(new_array);
    }
  };

  const isSameUser = (a, b) => a.name === b.name && a.name === b.name;

  const onlyInLeft = (left, right, compareFunction) =>
    left.filter(
      (leftValue) =>
        !right.some((rightValue) => compareFunction(leftValue, rightValue))
    );

  const getCollectionData = () => {
    if (selectedArray.length > 0) {
      const temp = editData?.collectionBuilder?.collections.map(
        ({ _id, ...rest }) => ({
          ...rest,
        })
      );
      if (temp.length > 0) {
        const array1 = onlyInLeft(temp, selectedArray, isSameUser);
        const array2 = onlyInLeft(selectedArray, temp, isSameUser);
        const uniqueValues = [...array1, ...array2];
        return uniqueValues.length > 0
          ? [...uniqueValues, ...selectedArray]
          : selectedArray;
      } else {
        return selectedArray;
      }
    } else {
      const modifiedArray = editData?.collectionBuilder?.collections?.map(
        ({ _id, ...rest }) => ({
          ...rest,
        })
      );
      return modifiedArray;
    }
  };

  const getContentCollectionData = () => {
    if (contentManagerArray.length > 0) {
      const temp = editData?.contentManager?.collections.map(
        ({ _id, ...rest }) => ({
          ...rest,
        })
      );
      if (temp.length > 0) {
        const array1 = onlyInLeft(temp, contentManagerArray, isSameUser);
        const array2 = onlyInLeft(contentManagerArray, temp, isSameUser);
        const uniqueValues = [...array1, ...array2];
        return uniqueValues.length > 0
          ? [...uniqueValues, ...contentManagerArray]
          : contentManagerArray;
      } else {
        return contentManagerArray;
      }
    } else {
      const modifiedArray = editData?.contentManager?.collections?.map(
        ({ _id, ...rest }) => ({
          ...rest,
        })
      );
      return modifiedArray;
    }
  };

  const onFinish = (values) => {
    const payload = editData
      ? {
          id: editData?._id,
          name: values.name,
          description: values.description,
          collectionBuilder: {
            newCollection: values.newCollection,
            collections: getCollectionData(),
          },
          contentManager: {
            collections: getContentCollectionData(),
          },
        }
      : {
          name: values.name,
          description: values.description,
          collectionBuilder: {
            newCollection: values.newCollection,
            collections: selectedArray,
          },
          contentManager: {
            collections: contentManagerArray,
          },
        };
    dispatch(editData ? updateRole(payload) : addNewRole(payload));
    handleModelCancel();
  };

  const getSelectedData = (value) => {
    let dataArr;
    editData?.collectionBuilder?.collections?.map((data) => {
      if (data.collectionName === value) {
        dataArr = Object.keys(data).filter((e) => data[e] === true);
      }
      return true;
    });
    const temp = dataArr?.map((e) => e.toUpperCase());
    return temp;
  };

  const getContentSelectedData = (value) => {
    let dataArr;
    editData?.contentManager?.collections?.map((data) => {
      if (data.collectionName === value) {
        dataArr = Object.keys(data).filter((e) => data[e] === true);
      }
      return true;
    });
    const temp = dataArr?.map((e) => e.toUpperCase());
    return temp;
  };

  return (
    <>
      <Button type="primary" onClick={() => setOpenModal(true)}>
        <PlusOutlined />
        Add new roles
      </Button>
      <Modal
        width={750}
        style={{ top: 24 }}
        open={openModal || editData}
        onCancel={handleModelCancel}
        title={
          <Space direction="vertical" size={0}>
            <Typography style={{ fontWeight: 600, fontSize: 19 }}>
              Create a role
            </Typography>
            <Typography style={{ color: "#8c8c8c", fontSize: 13 }}>
              Define the rights given to the role
            </Typography>
          </Space>
        }
        footer={[
          <Button onClick={handleModelCancel} key="cancel">
            Cancel
          </Button>,
          <Button
            key="ok"
            type="primary"
            htmlType="submit"
            onClick={() => form.submit()}
          >
            {editData ? "Update" : "Add"}
          </Button>,
        ]}
      >
        <Form
          key={refreshKey}
          form={form}
          onFinish={onFinish}
          layout="vertical"
          initialValues={{
            name: "",
            description: "",
            newCollection: false,
          }}
        >
          <Row gutter={8} style={{ marginTop: 15 }}>
            <Col span={12}>
              <Form.Item
                label="Name"
                name="name"
                rules={[
                  {
                    required: true,
                    message: "This field is required",
                  },
                ]}
              >
                <Input name="name" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Description"
                name="description"
                rules={[
                  {
                    required: true,
                    message: "This field is required",
                  },
                ]}
              >
                <Input name="description" />
              </Form.Item>
            </Col>
          </Row>

          <Divider orientation="left" orientationMargin={0}>
            Collection Builder
          </Divider>
          <Space direction="vertical">
            <Row>
              <Col span={24}>
                <Form.Item
                  name="newCollection"
                  valuePropName="checked"
                  style={{ marginBottom: 8 }}
                >
                  <Checkbox>Can create new collection</Checkbox>
                </Form.Item>
              </Col>
              <Col span={12} style={{ fontWeight: 600 }}>
                Collection Type
              </Col>
              <Col span={12}>
                <Row>
                  <Col span={6} style={{ fontSize: 12, fontWeight: 600 }}>
                    CREATE
                  </Col>
                  <Col span={6} style={{ fontSize: 12, fontWeight: 600 }}>
                    READ
                  </Col>
                  <Col span={6} style={{ fontSize: 12, fontWeight: 600 }}>
                    UPDATE
                  </Col>
                  <Col span={6} style={{ fontSize: 12, fontWeight: 600 }}>
                    DELETE
                  </Col>
                </Row>
              </Col>
            </Row>
            {collections ? (
              collections.map((val, i) => (
                <Checkbox.Group
                  key={i}
                  defaultValue={getSelectedData(val.name)}
                  onChange={(value) => handleChange(value, val)}
                  style={{ width: "100%" }}
                >
                  <Row>
                    <Col span={12}>{val.name}</Col>
                    <Col span={12}>
                      <Row>
                        <Col span={6}>
                          <Checkbox value="CREATE" />
                        </Col>
                        <Col span={6}>
                          <Checkbox value="READ" />
                        </Col>
                        <Col span={6}>
                          <Checkbox value="UPDATE" />
                        </Col>
                        <Col span={6}>
                          <Checkbox value="DELETE" />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Checkbox.Group>
              ))
            ) : (
              <Empty />
            )}
          </Space>

          <Divider orientation="left" orientationMargin={0}>
            Content Manager
          </Divider>
          <Space direction="vertical">
            <Row>
              <Col span={12} style={{ fontWeight: 600 }}>
                Collection Type
              </Col>
              <Col span={12}>
                <Row>
                  <Col span={6} style={{ fontSize: 12, fontWeight: 600 }}>
                    CREATE
                  </Col>
                  <Col span={6} style={{ fontSize: 12, fontWeight: 600 }}>
                    READ
                  </Col>
                  <Col span={6} style={{ fontSize: 12, fontWeight: 600 }}>
                    UPDATE
                  </Col>
                  <Col span={6} style={{ fontSize: 12, fontWeight: 600 }}>
                    DELETE
                  </Col>
                </Row>
              </Col>
            </Row>
            {collections ? (
              collections.map((val, i) => (
                <Checkbox.Group
                  key={i}
                  defaultValue={getContentSelectedData(val.name)}
                  onChange={(value) => handleContentManager(value, val)}
                  style={{ width: "100%" }}
                >
                  <Row>
                    <Col span={12}>{val.name}</Col>
                    <Col span={12}>
                      <Row>
                        <Col span={6}>
                          <Checkbox value="CREATE" />
                        </Col>
                        <Col span={6}>
                          <Checkbox value="READ" />
                        </Col>
                        <Col span={6}>
                          <Checkbox value="UPDATE" />
                        </Col>
                        <Col span={6}>
                          <Checkbox value="DELETE" />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Checkbox.Group>
              ))
            ) : (
              <Empty />
            )}
          </Space>
        </Form>
      </Modal>
    </>
  );
};
export default AddNewRole;
