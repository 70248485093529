import { useCallback, useMemo, useState } from "react";

import ReactFlow, {
  Background,
  ConnectionMode,
  Controls,
  addEdge,
  applyEdgeChanges,
  applyNodeChanges,
} from "reactflow";

import { initialNodes, initialEdges } from "./initial-element";
import TextUpdaterNode from "./components/TextUpdaterNode";
import ActionNode from "./components/ActionNode";
import ButtonEdge from "./components/ButtonEdge";

const Trigger = () => {
  const [nodes, setNodes] = useState(initialNodes);
  const [edges, setEdges] = useState(initialEdges);

  const onNodesChange = useCallback(
    (changes) => setNodes((nds) => applyNodeChanges(changes, nds)),
    [setNodes]
  );

  const onEdgesChange = useCallback(
    (changes) => {
      setEdges((eds) => applyEdgeChanges(changes, eds));
    },
    [setEdges]
  );

  const onConnect = useCallback(
    (connection) => {
      setEdges((eds) => addEdge(connection, eds));
    },
    [setEdges]
  );

  const onEdgeClick = (evt, id) => {
    let updateNodes = nodes;
    const temp = nodes.filter((item) => item.id === id.toString());
    evt.stopPropagation();
    const newNode = {
      id: `${+id + +"1"}`,
      type: "textUpdater",
      position: {
        x: 100,
        y: temp[0].position.y + 150,
      },
    };

    updateNodes.forEach((obj, index) => {
      if (obj.id > id) {
        updateNodes[index] = {
          ...obj,
          id: `${+obj.id + +"1"}`,
          position: { x: 100, y: obj.position.y + 150 },
        };
      }
    });

    let edgesIds = [];
    edges.map((item) => {
      edgesIds.push(Number(item.id));
      return true;
    });
    const maxId = Math.max(...edgesIds);
    const newEds = {
      id: `${+maxId + +"1"}`,
      source: `${+maxId + +"1"}`,
      target: `${+maxId + +"2"}`,
      type: "buttonedge",
    };

    setTimeout(() => {
      setNodes([...updateNodes, newNode]);
      setEdges((eds) => [...eds, newEds]);
    }, 100);
  };

  const handleNodes = (node) => {
    let ids = [];
    let btn = [];
    node.map((object) => {
      if (object.id !== "NaN" && object.id !== "addNode") {
        ids.push(Number(object.id));
      } else {
        btn.push(object);
      }
      return true;
    });
    const maxId = Math.max(...ids);
    const obj = node.filter((item) => item.id === maxId.toString());
    const tempObj = node.filter((item) => item.type !== "addNewNode");
    const data = [
      ...tempObj,
      {
        id: `${maxId + 1}`,
        type: "textUpdater",
        position: { x: 100, y: obj[0]?.position?.y + 150 },
      },
      {
        id: "addNode",
        type: "addNewNode",
        position: { x: 100, y: btn[0]?.position?.y + 150 },
      },
    ];

    setEdges((eds) => [
      ...eds,
      {
        id: `${maxId + 1}`,
        source: `${maxId}`,
        target: `${maxId + 1}`,
        type: "buttonedge",
      },
    ]);
    return data;
  };

  const handleNEwNode = () => {
    setNodes((nds) => handleNodes(nds));
  };

  const renderBottomButton = () => {
    return (
      <div
        style={{
          fontSize: 12,
        }}
      >
        <button
          style={{
            width: 20,
            height: 20,
            backgroundColor: "#eee",
            border: "1px solid #fff",
            cursor: "pointer",
            borderRadius: "50%",
            lineHeight: 1,
            marginLeft: 100,
          }}
          onClick={handleNEwNode}
        >
          +
        </button>
      </div>
    );
  };
  const nodeTypes = useMemo(
    () => ({
      textUpdater: (props) => TextUpdaterNode(props),
      actionTrigger: (props) => ActionNode(props),
      addNewNode: () => renderBottomButton(),
    }),
    // eslint-disable-next-line
    []
  );

  const edgeTypes = {
    buttonedge: (props) => ButtonEdge(props, onEdgeClick),
  };

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        connectionMode={ConnectionMode.Loose}
      >
        <Controls />
        <Background />
      </ReactFlow>
    </div>
  );
};

export default Trigger;
