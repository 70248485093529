import { Button, Flex, Typography } from "antd";
import { useNavigate } from "react-router-dom";

const PageNotFound = () => {
  const navigate = useNavigate();

  return (
    <Flex vertical align="center" justify="center" style={{ height: "100vh" }}>
      <Typography.Title level={3} style={{ marginTop: 0, marginBottom: 16 }}>
        404
      </Typography.Title>
      <Typography.Title level={1} style={{ marginTop: 0, marginBottom: 24 }}>
        Page Not Found!
      </Typography.Title>
      <Button type="primary" onClick={() => navigate("/")}>
        Go To Dashboard
      </Button>
    </Flex>
  );
};

export default PageNotFound;
