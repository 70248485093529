import { Layout, Menu, Typography } from "antd";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useContentStatus } from "../../../hooks";

const ModuleLayout = ({ children }) => {
  const navigate = useNavigate();
  // const { permissions } = useContentManagerStatus();
  const permissionCheck = useContentStatus();

  const [collapsed, setCollapsed] = useState(false);

  const collections = useSelector((state) => state.builder.collections);

  const collectionsMenu = [{ type: "divider" }];

  collections?.map((c) => {
    const check = permissionCheck(c._id);
    return collectionsMenu.push({
      key: c._id,
      label: c.name,
      onClick: () => {
        localStorage.setItem("key", c._id);
        navigate(`/content-manager/${c._id}`);
      },
      disabled: !(check.read || check.create || check.update || check.delete),
    });
  });

  return (
    <Layout style={{ height: "100%", margin: "-24px" }}>
      <Layout.Sider
        theme="light"
        collapsible
        width={240}
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
      >
        <Typography.Title level={3} style={{ padding: "0 24px" }}>
          {collapsed ? "C" : "Collections"}
        </Typography.Title>
        <Menu
          theme="light"
          mode="inline"
          defaultSelectedKeys={[`${localStorage.getItem("key")}`]}
          items={collectionsMenu}
          // onSelect={(item) => localStorage.setItem("key", item.key)}
        />
      </Layout.Sider>
      <Layout.Content style={{ padding: "24px" }}>{children}</Layout.Content>
    </Layout>
  );
};

export default ModuleLayout;
