import { Typography } from "antd";

import ModuleLayout from "./components/ModuleLayout";

const ContentManager = () => {
  return (
    <ModuleLayout>
      <Typography>Select the collection</Typography>
    </ModuleLayout>
  );
};

export default ContentManager;
