import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  ADD_ROLE,
  DELETE_ROLE,
  GET_ALL_ROLE,
  UPDATE_ROLE,
} from "../types/role";
import { showErrorMessage } from "../../helper/message";

export const getAllRole = createAsyncThunk(GET_ALL_ROLE, async () => {
  const response = await axios.get("/settings/roles");
  return response.data;
});

export const addNewRole = createAsyncThunk(
  ADD_ROLE,
  async (data, { dispatch }) => {
    try {
      await axios.post("/settings/role-create", data);
      dispatch(getAllRole());
    } catch (error) {
      showErrorMessage(error);
    }
  }
);

export const updateRole = createAsyncThunk(
  UPDATE_ROLE,
  async (data, { dispatch }) => {
    try {
      await axios.put(`/settings/role-update?id=${data.id}`, data);
      dispatch(getAllRole());
    } catch (error) {
      showErrorMessage(error);
    }
  }
);

export const deleteRole = createAsyncThunk(
  DELETE_ROLE,
  async (id, { dispatch }) => {
    try {
      await axios.delete(`/settings/role-delete?id=${id}`);
      dispatch(getAllRole());
    } catch (error) {
      showErrorMessage(error);
    }
  }
);
