import { createSlice } from "@reduxjs/toolkit";

import * as roleActions from "../actions/role";

const builderSlice = createSlice({
  name: "role",
  initialState: {
    roles: null,
    isLoading: true,
    hasError: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(roleActions.getAllRole.pending, (state, action) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(roleActions.getAllRole.fulfilled, (state, action) => {
        state.roles = action.payload;
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(roleActions.getAllRole.rejected, (state, action) => {
        state.hasError = true;
        state.isLoading = false;
      });
  },
});

export default builderSlice;
