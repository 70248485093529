import "./App.css";

import { io } from "socket.io-client";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useParams,
} from "react-router-dom";
import axios from "axios";

import AppLayout from "./components/Layout";
import Loading from "./pages/Loading";
import Login from "./pages/User/Login";
import Signup from "./pages/User/Signup";
import PageNotFound from "./pages/PageNotFound";

import Database from "./pages/Database";
import ContentManager from "./pages/ContentManager";
import CollectionBuilder from "./pages/CollectionBuilder";
import CollectionBuilderContent from "./pages/CollectionBuilder/content";
import ContentManagerContent from "./pages/ContentManager/content";

import Roles from "./pages/Admin/Roles";
import Members from "./pages/Admin/Member";

import Account from "./pages/Account";
import Logout from "./pages/User/Logout";

import { fetchUser } from "./redux/actions/auth";
import { getAllRole } from "./redux/actions/role";
import { getAllMember } from "./redux/actions/members";
import { fetchCollections } from "./redux/actions/builder";
import {
  useCollectionBuilderStatus,
  useCollectionStatus,
  useContentManagerStatus,
} from "./hooks";
import Trigger from "./pages/Trigger";
import Checkout from "./pages/Checkout";
const socket = io(process.env.REACT_APP_API_URL);

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.withCredentials = true;

const App = () => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.auth.user);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dispatch(fetchUser());
    setTimeout(() => {
      setLoading(false);
    }, 1000);
    socket.on("user-change", () => {
      dispatch(fetchUser());
      dispatch(fetchCollections());
    });
    socket.on("role-change", () => {
      if (user && user.role === "super-admin") {
        dispatch(getAllRole());
        dispatch(fetchUser());
      }
    });
    socket.on("member-change", () => {
      if (user && user.role === "super-admin") {
        dispatch(getAllMember());
        dispatch(fetchUser());
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user) {
      if (user.database && user.database.url) {
        dispatch(fetchCollections());
      }
      if (user.role === "super-admin") {
        dispatch(getAllRole());
        dispatch(getAllMember());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <>
      {loading && <Loading />}
      <Routes>
        <Route path="/signup" element={<Signup />} />
        <Route path="/login" element={<Login />} />
        <Route path="/logout" element={<Logout />} />
        <Route element={<AppLayout />}>
          <Route
            path="/"
            element={
              <RequireAuth>
                <Database />
              </RequireAuth>
            }
          />
          <Route
            path="/collection-builder"
            element={
              <RequireAuth>
                <CollectionPermission>
                  <CollectionBuilder />
                </CollectionPermission>
              </RequireAuth>
            }
          />
          <Route
            path="/collection-builder/:collectionId"
            element={
              <RequireAuth>
                <CollectionContentPermission>
                  <CollectionBuilderContent />
                </CollectionContentPermission>
              </RequireAuth>
            }
          />
          <Route
            path="/content-manager"
            element={
              <RequireAuth>
                <ContentPermission>
                  <ContentManager />
                </ContentPermission>
              </RequireAuth>
            }
          />
          <Route
            path="/connection-manager"
            element={
              <RequireAuth>
                <Trigger />
              </RequireAuth>
            }
          />
          <Route
            path="/checkout"
            element={
              <RequireAuth>
                <Checkout />
              </RequireAuth>
            }
          />
          <Route
            path="/content-manager/:collectionId"
            element={
              <RequireAuth>
                <ContentPermission>
                  <ContentManagerContent />
                </ContentPermission>
              </RequireAuth>
            }
          />
          <Route
            path="/roles"
            element={
              <RequireAuth>
                <RolesPermission>
                  <Roles />
                </RolesPermission>
              </RequireAuth>
            }
          />
          <Route
            path="/members"
            element={
              <RequireAuth>
                <MemberPermission>
                  <Members />
                </MemberPermission>
              </RequireAuth>
            }
          />
          <Route
            path="/account"
            element={
              <RequireAuth>
                <Account />
              </RequireAuth>
            }
          />
        </Route>
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </>
  );
};

function RequireAuth({ children }) {
  const auth = useSelector((state) => state.auth);
  const location = useLocation();

  if (!auth.user) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}

function CollectionPermission({ children }) {
  const location = useLocation();
  const collectionBuilderStatus = useCollectionBuilderStatus();

  if (collectionBuilderStatus.active) return children;
  else
    return (
      <Navigate to="/collection-builder" state={{ from: location }} replace />
    );
}

function CollectionContentPermission({ children }) {
  const location = useLocation();
  const permissionCheck = useCollectionStatus();

  const { collectionId } = useParams();

  const active = permissionCheck(collectionId);

  if (active) return children;
  else
    return (
      <Navigate to="/collection-builder" state={{ from: location }} replace />
    );
}

function ContentPermission({ children }) {
  const location = useLocation();
  const contentManagerStatus = useContentManagerStatus();

  if (contentManagerStatus.active) return children;
  else return <Navigate to="/" state={{ from: location }} replace />;
}

function RolesPermission({ children }) {
  const location = useLocation();
  const user = useSelector((state) => state.auth.user);

  if (user.role === "super-admin") return children;

  return <Navigate to="/" state={{ from: location }} replace />;
}

function MemberPermission({ children }) {
  const location = useLocation();
  const user = useSelector((state) => state.auth.user);

  if (user.role === "super-admin") return children;

  return <Navigate to="/" state={{ from: location }} replace />;
}

export default App;
