import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  ColorPicker,
  DatePicker,
  Empty,
  Form,
  Input,
  Modal,
  Select,
  Switch,
  Upload,
} from "antd";

import { addNewEntry, editEntry } from "../../../redux/actions/builder";
import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import Editor from "../../../components/Editor/Editor";
import { adminOptions } from "../../../helper/constants";

const AddNewContent = ({
  edit = null,
  disableEdit = false,
  disableAdd = false,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const [openModal, setOpenModal] = useState(false);
  const [refreshToken, setRefreshToken] = useState();

  const collection = useSelector((state) => state.builder.collection);

  const handleCancel = () => {
    setOpenModal(false);
    form.resetFields();
  };

  const handleSubmit = async (values, id) => {
    if (edit) {
      const payload = {
        collectionId: collection._id,
        collectionName: collection?.name,
        doc: values,
        docID: edit._id,
      };
      dispatch(editEntry(payload));
    } else {
      const payload = {
        collectionId: collection._id,
        collectionName: collection.name,
        doc: values,
      };
      dispatch(addNewEntry(payload));
    }

    handleCancel();
  };

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let baseURL = "";
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  const customUpload = (file, field) => {
    getBase64(file)
      .then((base64) => {
        form.setFieldValue(field, base64);
        setRefreshToken(new Date());
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const uploadButton = (str) => (
    <div>
      {!str && <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  return (
    <>
      {edit ? (
        <Button
          shape="circle"
          size="small"
          icon={<EditOutlined />}
          disabled={disableEdit}
          onClick={() => {
            form.setFieldsValue(edit);
            setOpenModal(true);
          }}
        />
      ) : (
        <Button
          type="primary"
          disabled={disableAdd}
          onClick={() => setOpenModal(true)}
        >
          <PlusOutlined />
          Add new entry
        </Button>
      )}

      <Modal
        forceRender
        style={{ top: 24 }}
        title="Create new entry"
        open={openModal}
        onCancel={handleCancel}
        footer={[
          <Button onClick={handleCancel} key="cancel">
            Cancel
          </Button>,
          <Button
            key="ok"
            type="primary"
            htmlType="submit"
            onClick={() => form.submit()}
          >
            {edit ? "Update" : "Add"}
          </Button>,
        ]}
      >
        <Form
          name="basic"
          form={form}
          onFinish={handleSubmit}
          layout="vertical"
          key={refreshToken}
        >
          {collection?.fields.length > 1 ? (
            collection?.fields.map((data, i) => {
              if (
                [
                  "plain-text",
                  "number",
                  "email",
                  "link",
                  "video-link",
                ].includes(data.type)
              ) {
                return (
                  <Form.Item
                    key={i}
                    label={data.name}
                    name={data.name}
                    rules={[
                      {
                        required: data.required,
                        message: "This field is required",
                      },
                      {
                        type: data.type === "email" && "email",
                        message: data.type === "email" && "Invalid E-mail",
                      },
                      {
                        message: data.type === "number" && "Invalid Number",
                        pattern:
                          data.type === "number" && new RegExp(/^[0-9]+$/),
                      },
                      {
                        type: data.type === "link" && "url",
                        message: data.type === "link" && "Invalid URL",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                );
              }
              if (data.type === "phone") {
                return (
                  <Form.Item
                    key={i}
                    label={data.name}
                    name={data.name}
                    rules={[
                      {
                        required: data.required,
                        message: "This field is required",
                      },
                      {
                        message: "Invalid Number",
                        pattern: new RegExp(/^[0-9]+$/),
                      },
                      { min: 10, message: "Phone must be 10 characters." },
                      { max: 10, message: "Phone must be 10 characters." },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                );
              }
              if (data.type === "password") {
                return (
                  <Form.Item
                    key={i}
                    label={data.name}
                    name={data.name}
                    rules={[
                      {
                        required: data.required,
                        message: "This field is required",
                      },
                    ]}
                  >
                    <Input.Password />
                  </Form.Item>
                );
              }
              if (data.type === "option") {
                return (
                  <Form.Item
                    key={i}
                    label={data.name}
                    name={data.name}
                    rules={[
                      {
                        required: data.required,
                        message: "This field is required",
                      },
                    ]}
                  >
                    <Select options={adminOptions} />
                  </Form.Item>
                );
              }
              if (data.type === "rich-text") {
                return (
                  <Form.Item key={i} label={data.name} name={data.name}>
                    <Editor
                      data={form.getFieldValue(data.name)}
                      handleChange={(value) => {
                        form.setFieldValue(data.name, value);
                      }}
                    />
                  </Form.Item>
                );
              }
              if (data.type === "date-time" && data.name !== "CreatedAt") {
                return (
                  <Form.Item
                    key={i}
                    label={data.name}
                    name={data.name}
                    rules={[
                      {
                        required: data.required,
                        message: "This field is required",
                      },
                    ]}
                  >
                    <DatePicker
                      value={form.getFieldValue(data.name)}
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                );
              }
              if (data.type === "switch") {
                return (
                  <Form.Item
                    key={i}
                    label={data.name}
                    name={data.name}
                    rules={[
                      {
                        required: data.required,
                        message: "This field is required",
                      },
                    ]}
                  >
                    <Switch />
                  </Form.Item>
                );
              }
              if (data.type === "color") {
                return (
                  <Form.Item
                    key={i}
                    label={data.name}
                    name={data.name}
                    rules={[
                      {
                        required: data.required,
                        message: "This field is required",
                      },
                    ]}
                  >
                    <ColorPicker
                      defaultFormat="hex"
                      format="hex"
                      defaultValue="#FFFFFF"
                      allowClear={true}
                      showText
                      onChange={(val, hex) => {
                        form.setFieldValue(data.name, hex);
                      }}
                    />
                  </Form.Item>
                );
              }
              if (data.type === "image") {
                return (
                  <Form.Item
                    key={i}
                    label={data.name}
                    name={data.name}
                    rules={[
                      {
                        required: data.required,
                        message: "This field is required",
                      },
                    ]}
                  >
                    <Upload
                      name="avatar"
                      listType="picture-card"
                      className="avatar-uploader"
                      showUploadList={false}
                      customRequest={({ onError, onSuccess, file }) =>
                        customUpload(file, data.name)
                      }
                    >
                      {form.getFieldValue(data.name) ? (
                        <img
                          src={form.getFieldValue(data.name)}
                          alt="avatar"
                          style={{ width: "100%" }}
                        />
                      ) : (
                        uploadButton(form.getFieldValue(data.name))
                      )}
                    </Upload>
                  </Form.Item>
                );
              }
              if (data.type === "reference") {
                return (
                  <Form.Item
                    key={i}
                    label={data.name}
                    name={data.name}
                    rules={[
                      {
                        required: data.required,
                        message: "This field is required",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                );
              }
              if (data.type === "multi-reference") {
                return (
                  <Form.Item
                    key={i}
                    label={data.name}
                    name={data.name}
                    rules={[
                      {
                        required: data.required,
                        message: "This field is required",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                );
              }

              return true;
            })
          ) : (
            <Empty description="Add fields to the collection" />
          )}
        </Form>
      </Modal>
    </>
  );
};

export default AddNewContent;
