import { useState } from "react";
import { Table, Button, Modal, Space } from "antd";
import {
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";

import PageTitle from "../../../components/Page/PageTitle";
import { addNewRole, deleteRole } from "../../../redux/actions/role";
import AddNewRole from "./AddNewRole";

const Roles = () => {
  const dispatch = useDispatch();

  const [editData, setEditData] = useState(undefined);

  const rolesData = useSelector((state) => state.role.roles);

  const handleDuplicateData = (data) => {
    const payload = {
      name: `${data?.name} copy`,
      description: data?.description,
      collections: data?.collections,
    };

    dispatch(addNewRole(payload));
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 200,
    },
    {
      title: "Slug",
      dataIndex: "slug",
      key: "slug",
      width: 200,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Users",
      dataIndex: "userCount",
      key: "users",
      width: 80,
    },
    {
      title: "Action",
      dataIndex: "",
      key: "",
      width: 150,
      render: (record) => (
        <>
          <Space>
            <Button
              size="small"
              shape="circle"
              icon={<CopyOutlined />}
              onClick={() => handleDuplicateData(record)}
            />
            <Button
              size="small"
              shape="circle"
              icon={<EditOutlined />}
              onClick={() => setEditData(record)}
              disabled={
                record.slug === "super-admin" ||
                record.slug === "content-manager" ||
                record.slug === "collection-manager"
              }
            />
            <Button
              danger
              size="small"
              shape="circle"
              icon={<DeleteOutlined />}
              disabled={
                record.slug === "super-admin" ||
                record.slug === "content-manager" ||
                record.slug === "collection-manager"
              }
              onClick={() =>
                Modal.confirm({
                  title: "Are you sure you want to delete it? ",
                  icon: (
                    <ExclamationCircleOutlined
                      style={{ color: "red", paddingTop: 1 }}
                    />
                  ),
                  cancelText: "No",
                  okText: "Yes",
                  onOk() {
                    dispatch(deleteRole(record._id));
                  },
                })
              }
            />
          </Space>
        </>
      ),
    },
  ];

  return (
    <>
      <PageTitle
        title="Roles"
        subtitle="List of roles"
        action={<AddNewRole editData={editData} setEditData={setEditData} />}
      />
      <Table
        size="small"
        dataSource={rolesData}
        columns={columns}
        rowKey={(r) => r._id}
        pagination={false}
      />
    </>
  );
};

export default Roles;
