export const FETCH_COLLECTIONS = "builder/collection";
export const FETCH_COLLECTION_BY_ID = "builder/collectionId";

export const ADD_COLLECTIONS = "builder/add_collection";
export const DELETE_COLLECTION = "builder/delete_collection";

export const ADD_COLLECTIONS_FIELD = "collection/field-create";
export const UPDATE_COLLECTIONS_FIELD = "collection/field-update";
export const DELETE_COLLECTIONS_FIELD = "collection/field-delete";

export const FETCH_CONTENT_BY_ID = "content/collectionId";
export const ADD_ENTRY = "content/create";
export const UPDATE_ENTRY = "content/update";
export const DELETE_ENTRY = "content/delete";

export const UPDATE_VALIDATION = "validation/update";
export const FETCH_VALIDATION = "validations/get";
