import React from "react";
import { BaseEdge, EdgeLabelRenderer, getBezierPath } from "reactflow";

export default function CustomEdge(
  {
    id,
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    style = {},
    markerEnd,
  },
  onEdgeClick
) {
  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  return (
    <>
      <BaseEdge path={edgePath} markerEnd={markerEnd} style={style} />
      <EdgeLabelRenderer>
        <div
          style={{
            position: "absolute",
            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
            fontSize: 12,
            pointerEvents: "all",
          }}
          className="nodrag nopan"
        >
          <button
            style={{
              width: 20,
              height: 20,
              backgroundColor: "#eee",
              border: "1px solid #fff",
              cursor: "pointer",
              borderRadius: "50%",
              lineHeight: 1,
            }}
            onClick={(event) => onEdgeClick(event, id)}
          >
            +
          </button>
        </div>
      </EdgeLabelRenderer>
    </>
  );
}
