import { useState } from "react";

import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Layout, Menu, Typography } from "antd";

import AddNew from "./AddNewCollection";
import {
  useCollectionBuilderStatus,
  useCollectionStatus,
} from "../../../hooks";

const ModuleLayout = ({ children }) => {
  const navigate = useNavigate();
  const { permissions } = useCollectionBuilderStatus();
  const permissionCheck = useCollectionStatus();

  const [collapsed, setCollapsed] = useState(false);
  const [createNewCollectionModal, setCreateNewCollectionModal] =
    useState(false);
  const collections = useSelector((state) => state.builder.collections);

  const collectionsMenu = [{ type: "divider" }];

  collections?.map((c) => {
    const check = permissionCheck(c._id);
    return collectionsMenu.push({
      key: c._id,
      label: c.name,
      onClick: () => {
        localStorage.setItem("key", c._id);
        navigate(`/collection-builder/${c._id}`);
      },
      disabled: !(check.read || check.create || check.update || check.delete),
    });
  });

  if (permissions.newCollection) {
    collectionsMenu.push(
      { type: "divider" },
      {
        key: "new",
        label: "+ Create New Collection",
        onClick: () => setCreateNewCollectionModal(true),
      }
    );
  }

  return (
    <Layout style={{ height: "100%", margin: "-24px" }}>
      <Layout.Sider
        theme="light"
        collapsible
        width={240}
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
      >
        <Typography.Title level={3} style={{ padding: "0 24px" }}>
          {collapsed ? "C" : "Collections"}
        </Typography.Title>
        <Menu
          theme="light"
          mode="inline"
          defaultSelectedKeys={[`${localStorage.getItem("key")}`]}
          items={collectionsMenu}
        />
      </Layout.Sider>
      <Layout.Content style={{ padding: "24px" }}>
        {children}
        <AddNew
          open={createNewCollectionModal}
          closeModal={() => setCreateNewCollectionModal(false)}
        />
      </Layout.Content>
    </Layout>
  );
};

export default ModuleLayout;
