import { createSlice } from "@reduxjs/toolkit";

import * as builderActions from "../actions/builder";

const builderSlice = createSlice({
  name: "builder",
  initialState: {
    collections: null,
    collection: null,
    validation: null,
    content: [],
    isLoading: true,
    hasError: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(builderActions.fetchCollections.pending, (state, action) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(builderActions.fetchCollections.fulfilled, (state, action) => {
        state.collections = action.payload;
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(builderActions.fetchCollections.rejected, (state, action) => {
        state.collections = null;
        state.hasError = true;
        state.isLoading = false;
      })
      .addCase(builderActions.fetchValidationByID.pending, (state, action) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(
        builderActions.fetchValidationByID.fulfilled,
        (state, action) => {
          state.validation = action.payload;
          state.isLoading = false;
          state.hasError = false;
        }
      )
      .addCase(builderActions.fetchValidationByID.rejected, (state, action) => {
        state.validation = null;
        state.hasError = true;
        state.isLoading = false;
      })
      .addCase(builderActions.fetchCollectionByID.pending, (state, action) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(
        builderActions.fetchCollectionByID.fulfilled,
        (state, action) => {
          state.collection = action.payload;
          state.isLoading = false;
          state.hasError = false;
        }
      )
      .addCase(builderActions.fetchCollectionByID.rejected, (state, action) => {
        state.collection = null;
        state.hasError = true;
        state.isLoading = false;
      })
      .addCase(builderActions.fetchContentByID.pending, (state, action) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(builderActions.fetchContentByID.fulfilled, (state, action) => {
        state.content = action.payload;
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(builderActions.fetchContentByID.rejected, (state, action) => {
        state.content = [];
        state.hasError = true;
        state.isLoading = false;
      });
  },
});

export default builderSlice;
