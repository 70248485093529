import { useSelector } from "react-redux";

export function useCollectionBuilderStatus() {
  const user = useSelector((state) => state.auth.user);

  let active = false;
  let permissions = null;

  if (user && user.database && user.database.url) {
    permissions = user.permissions.collectionBuilder;

    if (user.role === "super-admin" || user.role === "collection-manager") {
      active = true;
    } else if (permissions.newCollection) {
      active = true;
    } else if (permissions.collections.length > 0) {
      active = true;
    }
  }

  const collectionBuilderStatus = { active, permissions };

  return collectionBuilderStatus;
}

export function useContentManagerStatus() {
  const user = useSelector((state) => state.auth.user);

  let active = false;
  let permissions = null;

  if (user && user.database && user.database.url) {
    permissions = user && user.permissions.contentManager;

    if (user.role === "super-admin" || user.role === "content-manager") {
      active = true;
    } else if (permissions.collections.length > 0) {
      active = true;
    }
  }

  const contentManagerStatus = { active, permissions };

  return contentManagerStatus;
}

export function useCollectionManagerStatus() {
  const user = useSelector((state) => state.auth.user);
  let active = false;
  let permissions = null;

  if (user && user.database && user.database.url) {
    permissions = user && user.permissions.contentManager;

    if (user.role === "super-admin" || user.role === "collection-manager") {
      active = true;
    } else if (permissions.collections.length > 0) {
      active = true;
    }
  }

  const collectionManagerStatus = { active, permissions };

  return collectionManagerStatus;
}

export function useCollectionStatus() {
  const user = useSelector((state) => state.auth.user);
  const collections = useSelector((state) => state.builder.collections);
  const { permissions } = useCollectionBuilderStatus();

  const permissionCheck = (collectionId) => {
    const collection = collections?.find((c) => String(c._id) === collectionId);

    if (user.role === "super-admin" || user._id === collection?.createdBy) {
      return {
        collectionId: collection?._id,
        collectionName: collection?.name,
        create: true,
        read: true,
        update: true,
        delete: true,
      };
    }

    const collectionPermission = permissions.collections?.find(
      (c) => String(c.collectionId) === String(collectionId)
    );

    if (!collectionPermission) {
      return false;
    }

    return collectionPermission;
  };

  return permissionCheck;
}

export function useContentStatus() {
  const user = useSelector((state) => state.auth.user);
  const collections = useSelector((state) => state.builder.collections);
  const { permissions } = useContentManagerStatus();

  const permissionCheck = (collectionId) => {
    if (user.role === "super-admin") {
      const collection = collections?.find(
        (c) => String(c._id) === collectionId
      );
      return {
        collectionId: collection._id,
        collectionName: collection.name,
        create: true,
        read: true,
        update: true,
        delete: true,
      };
    }

    const collectionPermission = permissions.collections.find(
      (c) => String(c.collectionId) === String(collectionId)
    );

    if (!collectionPermission) {
      return false;
    }

    return collectionPermission;
  };

  return permissionCheck;
}
