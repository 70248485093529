import { useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Loading from "../Loading";
import { logout } from "../../redux/actions/auth";
import { useDispatch } from "react-redux";

const Logout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    axios.get("/auth/logout").then((response) => {
      dispatch(logout());
      setTimeout(() => navigate("/login", { replce: true }), 500);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Loading />;
};

export default Logout;
