import React from "react";
import { Input, Button, Form, Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { addNewCollection } from "../../../redux/actions/builder";
import { useNavigate } from "react-router-dom";

const AddNew = ({ open, closeModal }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const loading = useSelector((state) => state.builder.isLoading);

  const handleCancel = () => {
    closeModal();
    form.resetFields();
  };

  const handleOk = () => {
    form.submit();
    handleCancel();
  };

  const handleSubmit = (values) => {
    dispatch(
      addNewCollection({ collectionName: values.displayName, navigate })
    );
    handleCancel();
  };

  return (
    <Modal
      forceRender
      open={open}
      width={550}
      style={{ top: 24 }}
      title="Create New Collection"
      onCancel={handleCancel}
      onOk={handleOk}
      footer={[
        <Button
          key="save"
          type="primary"
          form="new-collection"
          loading={loading}
          htmlType="submit"
        >
          Create
        </Button>,
      ]}
    >
      <Form
        id="new-collection"
        form={form}
        onFinish={handleSubmit}
        layout="vertical"
        disabled={loading}
        initialValues={{ displayName: "" }}
      >
        <Form.Item
          label="Display Name"
          name="displayName"
          rules={[{ required: true, message: "This field is required" }]}
        >
          <Input allowClear />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddNew;
