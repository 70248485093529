import React from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Layout,
  Form,
  Input,
  Button,
  Typography,
  Space,
  Card,
  Row,
  Col,
  message,
} from "antd";
import axios from "axios";
import { showErrorMessage } from "../../helper/message";
import { useDispatch } from "react-redux";
import { fetchUser } from "../../redux/actions/auth";

const Signup = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogin = (values) => {
    axios
      .post("/auth/login", values)
      .then(() => {
        dispatch(fetchUser());
        setTimeout(() => {
          navigate("/");
        }, 500);
      })
      .catch((error) => {
        showErrorMessage(error);
        console.error("login error: ", error.response);
      });
  };

  const onSubmit = (values) => {
    axios
      .post("/auth/signup", values)
      .then((response) => {
        handleLogin({ email: values.email, password: values.password });
        dispatch(fetchUser());
        message.success("You registered successfully.");
      })
      .catch((error) => {
        if (error.stack.includes("409")) {
          message.error("A user with this email already exists!");
        } else {
          showErrorMessage(error);
        }
      });
  };

  return (
    <Layout>
      <Layout.Content
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "100vh",
        }}
      >
        <Card
          style={{
            width: "540px",
            margin: "24px auto",
            boxShadow: "0 0 20px 0 rgba(0, 0, 0, 0.2)",
          }}
        >
          <Space direction="vertical" size={24}>
            <Typography.Title level={3} style={{ margin: 0 }}>
              {process.env.REACT_APP_TEXT_LOGO}
            </Typography.Title>
            <Space direction="vertical" size={0}>
              <Typography.Title level={4} style={{ margin: 0 }}>
                Create your account!
              </Typography.Title>
              <Typography.Text>
                To continue to {process.env.REACT_APP_TEXT_LOGO}
              </Typography.Text>
            </Space>
            <Form
              layout="vertical"
              style={{ flex: 2 }}
              name="signup"
              onFinish={onSubmit}
            >
              <Row gutter={16}>
                <Col md={12} span={24}>
                  <Form.Item
                    label="First Name"
                    name="firstName"
                    rules={[
                      {
                        required: true,
                        message: "Please input your first name!",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col md={12} span={24}>
                  <Form.Item
                    label="Last Name"
                    name="lastName"
                    rules={[
                      {
                        required: true,
                        message: "Please input your last name!",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    type: "email",
                    message: "The input is not valid E-mail!",
                  },
                  {
                    required: true,
                    message: "Please input your email!",
                  },
                ]}
              >
                <Input autoComplete="email" />
              </Form.Item>

              <Form.Item
                label="Password"
                name="password"
                rules={[
                  { required: true, message: "Please input your password." },
                ]}
                hasFeedback
              >
                <Input.Password autoComplete="new-password" />
              </Form.Item>

              <Form.Item
                name="confirm"
                label="Confirm Password"
                dependencies={["password"]}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Please confirm your password!",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          "The new password that you entered do not match!"
                        )
                      );
                    },
                  }),
                ]}
              >
                <Input.Password autoComplete="new-password" />
              </Form.Item>

              <Form.Item>
                <Button type="primary" htmlType="submit" block>
                  Sign Up
                </Button>
              </Form.Item>
            </Form>
          </Space>
          <Typography>
            Have an account? <Link to="/login">Log In</Link>
          </Typography>
        </Card>
      </Layout.Content>
    </Layout>
  );
};

export default Signup;
