import React from "react";

import { ConfigProvider } from "antd";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import ReactDOM from "react-dom/client";

import App from "./App";
import store from "./redux/store";

import "reactflow/dist/style.css";

const theme = {
  token: {
    colorBgBase: "#F9F9FA",
    colorPrimary: "#404BDA",
    colorTextBase: "#272944",
    colorBgLayout: "#FFFFFF",
  },
};

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ConfigProvider theme={theme}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ConfigProvider>
    </Provider>
  </React.StrictMode>
);
