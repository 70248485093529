import {
  AlignLeftOutlined,
  BarsOutlined,
  BgColorsOutlined,
  CalendarOutlined,
  FieldBinaryOutlined,
  FileImageOutlined,
  FontSizeOutlined,
  IeOutlined,
  LinkOutlined,
  LockOutlined,
  PhoneOutlined,
  PlaySquareOutlined,
  SubnodeOutlined,
} from "@ant-design/icons";

export const CollectionFieldTypes = [
  {
    label: "Plain Text",
    value: "plain-text",
    icon: <FontSizeOutlined />,
    border: "1px solid green",
  },
  {
    label: "Rich Text",
    value: "rich-text",
    icon: <AlignLeftOutlined />,
    border: "1px solid #178ffe",
  },
  {
    label: "Image",
    value: "image",
    icon: <FileImageOutlined />,
    border: "1px solid #9736E8",
  },
  {
    label: "Video Link",
    value: "video-link",
    icon: <PlaySquareOutlined />,
    border: "1px solid #9736E8",
  },
  {
    label: "Link",
    value: "link",
    icon: <LinkOutlined />,
    border: "1px solid #9736E8",
  },
  {
    label: "Email",
    value: "email",
    icon: <IeOutlined />,
    border: "1px solid #f94c31",
  },
  {
    label: "Phone",
    value: "phone",
    icon: <PhoneOutlined />,
    border: "1px solid #f94c31",
  },
  {
    label: "Number",
    value: "number",
    icon: <FieldBinaryOutlined />,
    border: "1px solid #f94c31",
  },
  {
    label: "Date/Time",
    value: "date-time",
    icon: <CalendarOutlined />,
    border: "1px solid #ffa319",
  },
  {
    label: "Switch",
    value: "switch",
    icon: <SubnodeOutlined />,
    border: "1px solid #ffa319",
  },
  {
    label: "Password",
    value: "password",
    icon: <LockOutlined />,
    border: "1px solid #ffa319",
  },
  {
    label: "Color",
    value: "color",
    icon: <BgColorsOutlined />,
    border: "1px solid #178ffe",
  },
  {
    label: "Option",
    value: "option",
    icon: <BarsOutlined />,
    border: "1px solid #f94c31",
  },
];

export const adminOptions = [
  {
    value: "admin ",
    label: "Admin ",
  },
  {
    value: "member",
    label: "Member",
  },
];
