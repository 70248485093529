import { Flex, Spin } from "antd";

const Loading = () => {
  return (
    <Flex
      align="center"
      justify="center"
      style={{
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: "1000",
        position: "fixed",
        backgroundColor: "rgba(255, 255, 255, 1)",
      }}
    >
      <Spin size="large" />
    </Flex>
  );
};

export default Loading;
