import React from "react";
import {
  Layout,
  Form,
  Input,
  Button,
  Card,
  Space,
  Typography,
  notification,
} from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { fetchUser } from "../../redux/actions/auth";
import { showErrorMessage } from "../../helper/message";

const Login = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const [api, contextHolder] = notification.useNotification();

  const auth = useSelector((state) => state.auth);

  const from = location.state?.from?.pathname || "/";

  const onSubmit = (values) => {
    axios
      .post("/auth/login", values)
      .then(() => {
        dispatch(fetchUser());
      })
      .catch((error) => {
        if (error.response && error.response.data === "Unauthorized") {
          api["error"]({
            message: "User not found",
            description: "This user is not registered!",
          });
        } else {
          showErrorMessage(error);
        }
        console.error("login error: ", error.response);
      });
  };

  if (auth.user) {
    setTimeout(() => navigate(from ? from : "/", { replce: true }), 500);
  }

  return (
    <Layout>
      {contextHolder}
      <Layout.Content
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "100vh",
        }}
      >
        <Card
          style={{
            width: "540px",
            margin: "24px auto",
            boxShadow: "0 0 20px 0 rgba(0, 0, 0, 0.2)",
          }}
        >
          <Space direction="vertical" size={24}>
            <Typography.Title level={3} style={{ margin: 0 }}>
              {process.env.REACT_APP_TEXT_LOGO}
            </Typography.Title>
            <Space direction="vertical" size={0}>
              <Typography.Title level={4} style={{ margin: 0 }}>
                Sign in!
              </Typography.Title>
              <Typography.Text>
                To continue to {process.env.REACT_APP_TEXT_LOGO}
              </Typography.Text>
            </Space>
            <Form name="login" layout="vertical" onFinish={onSubmit}>
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Please input your email!",
                  },
                ]}
              >
                <Input autoComplete="email" />
              </Form.Item>

              <Form.Item
                label="Password"
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please input your password!",
                  },
                ]}
              >
                <Input.Password autoComplete="current-password" />
              </Form.Item>

              <Form.Item>
                <Button type="primary" htmlType="submit" block>
                  Log In
                </Button>
              </Form.Item>
            </Form>
          </Space>
          <Typography>
            New here? <Link to="/signup">Create a new account</Link>
          </Typography>
        </Card>
      </Layout.Content>
    </Layout>
  );
};

export default Login;
