import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  ADD_MEMBER,
  DELETE_MEMBER,
  EDIT_MEMBER,
  GET_MEMBER,
} from "../types/members";
import { showErrorMessage } from "../../helper/message";

export const getAllMember = createAsyncThunk(GET_MEMBER, async () => {
  try {
    const response = await axios.get("/settings/members");
    return response.data;
  } catch (error) {
    showErrorMessage(error);
  }
});

export const addNewMember = createAsyncThunk(
  ADD_MEMBER,
  async (data, { dispatch }) => {
    try {
      await axios.post("/settings/member-create", data);
      dispatch(getAllMember());
    } catch (error) {
      showErrorMessage(error);
    }
  }
);

export const editMember = createAsyncThunk(
  EDIT_MEMBER,
  async (data, { dispatch }) => {
    try {
      await axios.put("/settings/member-update", data);
      dispatch(getAllMember());
    } catch (error) {
      showErrorMessage(error);
    }
  }
);

export const deleteMember = createAsyncThunk(
  DELETE_MEMBER,
  async (id, { dispatch }) => {
    try {
      await axios.delete(`/settings/member-delete/${id}`);
      dispatch(getAllMember());
    } catch (error) {
      showErrorMessage(error);
    }
  }
);
