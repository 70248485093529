import { useEffect } from "react";
// import emailjs from "@emailjs/browser";
import { Col, Form, Input, Row, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";

import { addNewMember, editMember } from "../../../redux/actions/members";
const { Option } = Select;

const InviteUser = ({ form, handleModelCancel, memberEdit, rolesData }) => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    if (memberEdit) {
      form.setFieldsValue({
        to_fname: memberEdit?.firstName,
        to_lname: memberEdit?.lastName,
        to_email: memberEdit?.email,
        role: memberEdit?.role,
      });
    }
  }, [memberEdit, form]);

  const onFinish = (values) => {
    if (user) {
      const payload = {
        member: {
          _id: memberEdit?._id,
          email: values.to_email,
          firstName: values.to_fname,
          lastName: values.to_lname,
          role: values.role,
          password: values.password,
        },
      };

      if (memberEdit) {
        dispatch(editMember(payload));
        handleModelCancel();
      } else {
        dispatch(addNewMember(values));
        handleModelCancel();
        // emailjs
        //   .sendForm(
        //     process.env.EMAILJS_SERVICE_ID,
        //     process.env.EMAILJS_TEMPLATE_ID,
        //     form,
        //     process.env.EMAILJS_PUBLIC_KEY
        //   )
        //   .then(
        //     (result) => {
        //       console.info(result.text);
        //     },
        //     (error) => {
        //       console.error(error.text);
        //     }
        //   );
      }
    }
  };

  return (
    <>
      <Form
        form={form}
        onFinish={onFinish}
        layout="vertical"
        initialValues={{
          from_fname: user.firstName,
          from_lname: user.lastName,
          to_fname: "",
          to_lname: "",
          to_email: "",
          role: "",
        }}
      >
        <Row gutter={8}>
          <Col span={12}>
            <Form.Item hidden label="First Name" name="from_fname">
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item hidden label="Last Name" name="from_lname">
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="First Name"
              name="to_fname"
              rules={[
                {
                  required: true,
                  message: "This field is required",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Last Name"
              name="to_lname"
              rules={[
                {
                  required: true,
                  message: "This field is required",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Email"
              name="to_email"
              rules={[
                {
                  required: true,
                  message: "This field is required",
                },
                {
                  type: "email",
                  message: "Invalid E-mail",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Password"
              name="password"
              rules={[
                {
                  required: memberEdit ? false : true,
                  message: "This field is required",
                },
              ]}
            >
              <Input.Password />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Role"
              name="role"
              rules={[
                {
                  required: true,
                  message: "This field is required",
                },
              ]}
            >
              <Select style={{ width: "100%" }}>
                {rolesData.map((item) => (
                  <Option key={item._id} value={item.slug}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
};
export default InviteUser;
