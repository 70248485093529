import React, { useState } from "react";

import { useSelector } from "react-redux";
import { Avatar, Layout, Menu, Typography } from "antd";
import { Link, Outlet, useNavigate } from "react-router-dom";

import {
  DatabaseOutlined,
  LayoutOutlined,
  LogoutOutlined,
  NodeIndexOutlined,
  TrademarkCircleOutlined,
  UnorderedListOutlined,
  UserOutlined,
  UserSwitchOutlined,
} from "@ant-design/icons";

import {
  useCollectionBuilderStatus,
  useCollectionManagerStatus,
  useContentManagerStatus,
} from "../../hooks";

const { Sider, Content } = Layout;

const AppLayout = () => {
  const navigate = useNavigate();

  const collectionBuilderStatus = useCollectionBuilderStatus();
  const contentManagerStatus = useContentManagerStatus();
  const collectionManagerStatus = useCollectionManagerStatus();

  const [collapsed, setCollapsed] = useState(false);
  const user = useSelector((state) => state.auth.user);
  const roles = useSelector((state) => state.role.roles);

  const builderItems = [
    { type: "divider" },
    {
      type: "group",
      label: "Builder",
      children: [
        {
          key: "database",
          icon: <DatabaseOutlined />,
          label: "Database",
          onClick: () => navigate("/"),
        },
        collectionBuilderStatus.active && {
          key: "collection-builder",
          icon: <UnorderedListOutlined />,
          label: "Collection Builder",
          onClick: () => navigate("/collection-builder"),
        },
        contentManagerStatus.active && {
          key: "content-manager",
          icon: <LayoutOutlined />,
          label: "Content Manager",
          onClick: () => navigate("/content-manager"),
        },
        collectionManagerStatus.active && {
          key: "connection-manager",
          icon: <NodeIndexOutlined />,
          label: "Connection Manager",
          onClick: () => navigate("/connection-manager"),
        },
        {
          key: "checkout",
          icon: <NodeIndexOutlined />,
          label: "checkout",
          onClick: () => navigate("/checkout"),
        },
      ],
    },
  ];

  const adminItems = [
    { type: "divider" },
    {
      type: "group",
      label: "Admin",
      children: [
        {
          key: "roles ",
          icon: <TrademarkCircleOutlined />,
          label: "Roles",
          onClick: () => navigate("/roles"),
        },
        {
          key: "members",
          icon: <UserSwitchOutlined />,
          label: "Members",
          onClick: () => navigate("/members"),
        },
      ],
    },
  ];

  const userItems = [
    { type: "divider" },
    {
      type: "group",
      label: "User",
      children: [
        {
          key: "account",
          icon: <UserOutlined />,
          label: <Link to="/account">My Account</Link>,
        },
        {
          key: "logout",
          icon: <LogoutOutlined />,
          label: <Link to="/logout">Logout</Link>,
        },
      ],
    },
    { type: "divider" },
    {
      key: "user",
      icon: (
        <Avatar size="small">
          {user && user.firstName.charAt(0).toUpperCase()}
        </Avatar>
      ),
      label: user && !collapsed && (
        <>
          <Typography>
            {user.firstName} {user.lastName}
          </Typography>
          <Typography style={{ color: "#8c8c8c", fontSize: "10px" }}>
            {roles && roles.find((r) => r.slug === user.role)?.name}
          </Typography>
        </>
      ),
    },
  ];

  const navItems =
    user && user.role === "super-admin"
      ? [...builderItems, ...adminItems, ...userItems]
      : [...builderItems, ...userItems];

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider
        theme="light"
        collapsible
        width={240}
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
      >
        <Typography.Title level={3} style={{ padding: "0 24px" }}>
          {collapsed
            ? process.env.REACT_APP_TEXT_LOGO_SM
            : process.env.REACT_APP_TEXT_LOGO}
        </Typography.Title>
        <Menu
          theme="light"
          mode="inline"
          defaultSelectedKeys={["database"]}
          items={navItems}
        />
      </Sider>
      <Content style={{ padding: 24 }}>
        <Outlet />
      </Content>
    </Layout>
  );
};

export default AppLayout;
