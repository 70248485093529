import { useState } from "react";

import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Space,
  Typography,
} from "antd";

import { EditOutlined, PlusOutlined } from "@ant-design/icons";

import { CollectionFieldTypes } from "../../../helper/constants";
import {
  addCollectionField,
  updateCollectionField,
} from "../../../redux/actions/builder";

const AddNewFiels = ({
  edit = null,
  disableEdit = false,
  disableAdd = false,
}) => {
  const dispatch = useDispatch();
  const { collectionId } = useParams();

  const [form] = Form.useForm();

  const [fieldSelectionOpen, setFieldSelectionOpen] = useState(false);
  const [fieldNewOpen, setFieldNewOpen] = useState(false);
  const [fieldType, setFieldType] = useState(null);

  const handleCancelFieldSelection = () => {
    setFieldSelectionOpen(false);
  };

  const handleCancelFieldNew = () => {
    setFieldNewOpen(false);
    setFieldType(null);
    form.resetFields();
  };

  const handleSubmit = (values) => {
    if (edit) {
      const payload = {
        collectionId: collectionId,
        field: {
          ...edit,
          name: values.name,
          required: values.required,
          unique: values.unique,
          regex: values.regex,
          error: values.error,
        },
      };
      dispatch(updateCollectionField(payload));
    } else {
      const payload = {
        collectionId: collectionId,
        field: {
          name: values.name,
          type: fieldType?.value,
          required: values.required,
          unique: values.unique,
          regex: values.regex,
          error: values.error,
        },
      };
      dispatch(addCollectionField(payload));
    }
    handleCancelFieldNew();
  };

  return (
    <>
      {edit ? (
        <Button
          shape="circle"
          size="small"
          icon={<EditOutlined />}
          disabled={disableEdit}
          onClick={() => {
            form.setFieldsValue(edit);
            setFieldType(edit.type);
            setFieldNewOpen(true);
          }}
        />
      ) : (
        <Button
          type="primary"
          disabled={disableAdd}
          onClick={() => setFieldSelectionOpen(true)}
        >
          <PlusOutlined />
          Add new field
        </Button>
      )}

      {/* Field type selection modal */}
      <Modal
        title="Select a field for your collection type"
        onCancel={handleCancelFieldSelection}
        open={fieldSelectionOpen}
        footer={false}
        width={860}
        style={{ top: 24 }}
      >
        <Row gutter={16}>
          {CollectionFieldTypes.map((item, idx) => {
            return (
              <Col key={idx} md={4} span={12}>
                <Card
                  direction="vertical"
                  align="center"
                  onClick={() => {
                    setFieldNewOpen(item);
                    setFieldType(item);
                    setFieldSelectionOpen(false);
                  }}
                  style={{ cursor: "pointer", marginBottom: 16 }}
                >
                  <Typography style={{ fontSize: 18 }}>{item.icon}</Typography>
                  <Typography>{item.label}</Typography>
                </Card>
              </Col>
            );
          })}
        </Row>
      </Modal>

      {/* Field new modal */}
      <Modal
        forceRender
        style={{ top: 24 }}
        title={`Add New ${fieldType?.label} field`}
        open={fieldNewOpen}
        onCancel={handleCancelFieldNew}
        footer={[
          <Button
            key="ok"
            type="primary"
            htmlType="submit"
            onClick={() => form.submit()}
          >
            {edit ? "Update" : "Add"}
          </Button>,
        ]}
      >
        <Form
          form={form}
          onFinish={handleSubmit}
          layout="vertical"
          initialValues={{
            name: "",
            required: false,
            unique: false,
            regex: "",
            error: "",
          }}
        >
          <Space direction="vertical">
            <Form.Item
              label="Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: "This field is required",
                },
                {
                  validator: (_, value) =>
                    !value.includes(" ")
                      ? Promise.resolve()
                      : Promise.reject(
                          new Error(
                            "No space is allowed for the name of the attribute"
                          )
                        ),
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Space direction="horizontal">
              <Form.Item name="required" valuePropName="checked">
                <Checkbox>Required field</Checkbox>
              </Form.Item>
              <Form.Item name="unique" valuePropName="checked">
                <Checkbox>Unique field</Checkbox>
              </Form.Item>
            </Space>
            <Form.Item label="Regex Validation" name="regex">
              <Input />
            </Form.Item>
            <Form.Item
              label="Validation Error"
              name="error"
              rules={[
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!getFieldValue("regex") || value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error("This field is required"));
                  },
                }),
              ]}
            >
              <Input />
            </Form.Item>
          </Space>
        </Form>
      </Modal>
    </>
  );
};

export default AddNewFiels;
