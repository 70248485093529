export const initialNodes = [
  {
    id: "1",
    type: "actionTrigger",
    position: { x: 100, y: 100 },
  },
  {
    id: "2",
    type: "textUpdater",
    position: { x: 100, y: 250 },
  },
  {
    id: "addNode",
    type: "addNewNode",
    position: { x: 100, y: 330 },
  },
];

export const initialEdges = [
  {
    id: "1",
    source: "1",
    target: "2",
    type: "buttonedge",
  },
];
